
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import * as _ from "lodash";
import { SteamItemFragment } from "@/graphql/fragments";
import SteamItem from "@/components/steam/SteamItem.vue";
import CircleLoading from "@/components/loading/CircleLoading.vue";

@Component({
  components: {
    AdminTable,
    CircleLoading,
    SteamItem
  }
})
export default class AdminSteamItemPriceRule extends Vue {
  creationDialog = false;
  selected: any[] = [];
  additionalPartners = [
    { _id: null, name: "skinsplus", avatar: "", __typename: "User" }
  ];
  SteamItemFragment = SteamItemFragment;
  itemsDialogsShow = false;
  creation: any = {
    rule: {
      label: "rule",
      value: undefined,
      required: true,
      type: "string"
    },
    commission: {
      label: "commission",
      value: 1,
      required: true,
      type: "number"
    },
    appId: {
      label: "appId",
      value: "CSGO",
      required: true,
      type: "string",
      enum: ["CSGO", "DOTA2", "RUST"]
    },
    price: {
      label: "price",
      value: undefined,
      required: false,
      type: "number"
    },
    isRegex: {
      label: "isRegex",
      value: false,
      required: true,
      type: "boolean"
    },
    isPriceRule: {
      label: "isPriceRule",
      value: false,
      required: true,
      type: "boolean"
    },
    partnerUserId: {
      label: "partnerUserId",
      value: undefined,
      type: "string"
    }
  };

  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      align: "left",
      type: "string",
      classes: "ellipsis",
      style: "max-width: 15px"
    },
    {
      name: "rule",
      required: true,
      label: "rule",
      field: "rule",
      align: "left",
      type: "string",
      editable: true
    },
    {
      name: "appId",
      required: true,
      label: "appId",
      field: "appId",
      align: "left",
      type: "number",
      enum: [730, 570, 440, 252490],
      editable: true
    },
    {
      name: "partnerUserId",
      align: "center",
      label: "partnerUserId",
      field: "partnerUserId",
      editable: true,
      type: "string",
      classes: "ellipsis",
      style: "max-width: 15px"
    },
    {
      name: "partnerUser",
      align: "center",
      label: "partnerUser",
      field: "partnerUser",
      editable: false,
      type: "string",
      format: v => {
        return v ? v.name : "skinsplus";
      }
    },
    {
      name: "isRegex",
      align: "center",
      label: "isRegex",
      field: "isRegex",
      editable: true,
      type: "boolean"
    },
    {
      name: "isPriceRule",
      align: "center",
      label: "isPriceRule",
      field: "isPriceRule",
      editable: true,
      type: "boolean"
    },
    {
      name: "commission",
      align: "center",
      label: "commission",
      field: "commission",
      editable: true,
      type: "number"
    },
    {
      name: "price",
      align: "center",
      label: "price",
      field: "price",
      editable: true,
      type: "number"
    },
    {
      name: "totalPriceBuy",
      align: "center",
      label: "Итоговая цена (BUY)",
      type: "string",
      format: (v, row) => {
        const additionalCommission =
          (row.partnerUser ? row.partnerUser.partnerSteamItemCommission : {})[
            row.appId
          ] || 0.67;

        return typeof row.price === "number" && !row.isRegex
          ? Number(
              (row.price * row.commission * additionalCommission).toFixed(2)
            )
          : `${row.commission} * ${additionalCommission} * price`;
      }
    },
    {
      name: "totalPriceSell",
      align: "center",
      label: "Итоговая цена (SELL)",
      type: "string",
      format: (v, row) => {
        const additionalCommission =
          (row.partnerUser
            ? row.partnerUser.partnerWithdrawSteamItemCommission
            : {})[row.appId] || 0.73;

        return typeof row.price === "number" && !row.isRegex
          ? Number(
              (row.price * row.commission * additionalCommission).toFixed(2)
            )
          : `${row.commission} * ${additionalCommission} * price`;
      }
    },
    {
      name: "steamItemsCount",
      field: "steamItemsCount",
      align: "center",
      label: "кол-во предметов",
      type: "number"
    },
    {
      name: "enabled",
      field: "enabled",
      align: "center",
      label: "enabled",
      editable: true,
      type: "boolean"
    }
  ];

  created() {
    const serverName = this.$q.cookies.get("selected-server");
    if ((serverName || "").indexOf("SELL") !== -1) {
      this.additionalPartners = [];
    }
  }

  get lastSelected() {
    return _.last(this.selected) || null;
  }

  get type() {
    return (this.$q.cookies.get("selected-server") || "").indexOf("SELL") !== -1
      ? "SELL"
      : "BUY";
  }

  get creationVariables() {
    return Object.keys(this.creation).reduce((acc, c) => {
      acc[c] = this.creation[c].value;
      if (
        this.creation[c].type == "number" &&
        acc[c] !== undefined &&
        acc[c] !== ""
      ) {
        acc[c] = Number(acc[c]);
      }

      return acc;
    }, {});
  }
  creationReset() {
    Object.keys(this.creation).forEach(key => {
      this.creation[key].value = undefined;
    });
  }
}
